<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 비상사태구분 -->
          <c-select
            type="search"
            codeGroupCd="EAP_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="emergencyTypeCd"
            label="비상사태구분"
            v-model="searchParam.emergencyTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="비상조치계획서 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="sopEmergencyPlanId"
      :selection="popupParam.type"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergenct-action-plan-docu-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: '사업장명',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:150px',
          // },
          {
            name: 'emergencyPlanName',
            field: 'emergencyPlanName',
            label: '계획서명',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'emergencyTypeName',
            field: 'emergencyTypeName',
            label: '비상사태구분',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'emergencyClassCd',
            field: 'emergencyClassCd',
            label: '비상사태유형',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          // {
          //   name: 'traningScnario',
          //   field: 'traningScnario',
          //   label: '훈련시나리오',
          //   align: 'center',
          //   child: [
          //     {
          //       name: 'revisionNum',
          //       field: 'revisionNum',
          //       label: 'Rev.',
          //       align: 'center',
          //       sortable: true,
          //       style: 'width:60px',
          //     },
          //     {
          //       name: 'trainingScenarioName',
          //       field: 'trainingScenarioName',
          //       label: '훈련시나리오명',
          //       align: 'center',
          //       sortable: true,
          //       style: 'width:150px',
          //     },
          //     {
          //       name: 'trainingContentsSummary',
          //       field: 'trainingContentsSummary',
          //       label: '훈련내용요약',
          //       align: 'left',
          //       sortable: true,
          //       style: 'width:250px',
          //     },
          //   ]
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        emergencyTypeCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.listUrl = selectConfig.sop.eap.planDocu.list.pop.url;
      // url setting
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '비상조치계획서를 선택하세요.', // 비상조치계획서를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
